var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "infotDetai" }, [
      _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.dataObj.title))]),
      _c("p", { staticClass: "content" }, [
        _c(
          "span",
          {
            staticClass: "time",
            staticStyle: { "margin-top": "5px", display: "block" }
          },
          [_vm._v(_vm._s(_vm.dataObj.updateTime || _vm.dataObj.createTime))]
        )
      ]),
      _c("div", {
        staticClass: "edit",
        domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }