<template>
  <div>
    <div class="infotDetai">
      <p class="title">{{ dataObj.title }}</p>
      <p class="content">
        <span class="time" style="margin-top: 5px; display: block">{{ dataObj.updateTime || dataObj.createTime }}</span>
      </p>
      <div class="edit" v-html="dataObj.content"></div>
    </div>
  </div>
</template>

<script>
import { getNewDetail } from '@/api/park'

export default {
  components: {},
  data() {
    const { id } = this.$route.params
    return {
      id,
      dataObj: {},
      finishing: false
    }
  },
  computed: {
    role() {
      return this.type
    },
    isOrgRole() {
      return this.role === 'org' || this.role === 'employee'
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const that = this
      const { success, result } = await getNewDetail({
        id: that.id
      })
      if (success) {
        that.dataObj = result
      }
    }
  }
}
</script>

<style lang="less" scoped>
.infotDetai {
  margin-top: 10px;
  padding: 0 15px;

  .title {
    color: #333;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .content {
    margin-bottom: 28px;

    .time {
      color: #999;
      font-size: 12px;
    }

    img {
      float: right;
    }
  }

  .edit {
    margin-bottom: 30px;
    width: 100%;

    ::v-deep img {
      max-width: 100% !important;
      width: auto !important;
      height: auto !important;
    }

    ::v-deep p:has(> img) {
      text-indent: 0 !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
}
</style>
